$(function () {
    var mediaProductCount;
    var mediaInfoHtmlCountDef;
    var mediaInfoHtmlCountShow;
    checkPosition();

    $(window).on('load resize orientationchange', function( event ) {
        checkPosition();
    });
    function checkPosition() {
        if (window.matchMedia('(min-width: 1421px)').matches) {
            mediaProductCount = 7;
            mediaInfoHtmlCountDef = 10;
            mediaInfoHtmlCountShow = 5;
        }
        if (window.matchMedia('(max-width: 1420px)').matches) {
            mediaProductCount = 4;
            mediaInfoHtmlCountDef = 8;
            mediaInfoHtmlCountShow = 4;
        }
        if (window.matchMedia('(max-width: 768px)').matches) {
            mediaProductCount = 2;
            mediaInfoHtmlCountDef = 4;
            mediaInfoHtmlCountShow = 2;
        }
    }

    $('.homepage-products__wrapper').each(function() {
        $(this).find('.product-miniature').slice(0, mediaProductCount).show();
    });
    $('body').on('click touchstart', '.load-more__product', function (e) {
        e.preventDefault();
        $(this).closest('.homepage-products__wrapper').find('.product-miniature:hidden').slice(0, mediaProductCount).slideDown();
        if ($(this).closest('.homepage-products__wrapper').find('.product-miniature:hidden').length == 0) {
            $(this).addClass('disable');
        }
    });

    $('.homepage-section__custom-info-list').each(function() {
        $(this).find('li').slice(0, mediaInfoHtmlCountDef).show();
    });
    $('body').on('click', '.load-more__custom-info', function (e) {
        e.preventDefault();
        $(this).closest('.homepage-section__custom-info').find('.homepage-section__custom-info-list li:hidden').slice(0, mediaInfoHtmlCountShow).slideDown();
        if ($(this).closest('.homepage-section__custom-info').find('.homepage-section__custom-info-list li:hidden').length == 0) {
            $(this).addClass('disable');
        }
    });

});

