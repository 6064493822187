$( document ).ready(function() {
    var mediaProductCount;
    var mediaProductCount2;
    var mediaInfoHtmlCountDef;
    var mediaInfoHtmlCountShow;
    var mediaInfoHtmlCountDefBrands;
    checkPosition();

    $(window).on('load resize orientationchange', function( event ) {
        checkPosition();
    });
    function checkPosition() {
        if (window.matchMedia('(min-width: 1421px)').matches) {
            mediaProductCount = 8;
            mediaProductCount2 = 12;
            mediaInfoHtmlCountDef = 16;
            mediaInfoHtmlCountShow = 16;
            mediaInfoHtmlCountDefBrands = 6;
        }
        if (window.matchMedia('(max-width: 1420px)').matches) {
            mediaProductCount = 4;
            mediaProductCount2 = 8;
            mediaInfoHtmlCountDef = 8;
            mediaInfoHtmlCountDefBrands = 8;
            mediaInfoHtmlCountShow = 8;
        }
        if (window.matchMedia('(max-width: 768px)').matches) {
            mediaProductCount = 2;
            mediaProductCount2 = 4;
            mediaInfoHtmlCountDef = 15;
            mediaInfoHtmlCountDefBrands = 3;
            mediaInfoHtmlCountShow = 50;
            hideSpecificStrokes();
        }
    }

    $('.homepage-products__wrapper').each(function() {
      if($(this).parents('.six-row').length > 0) {
        $(this).find('.product-miniature').slice(0, mediaProductCount2).show();
      }
      else if ($(this).parents('.eight-row').length > 0) {
        $(this).find('.product-miniature').slice(0, mediaProductCount).show();
      }
      else {
        $(this).find('.product-miniature').slice(0, mediaProductCount).show();
      }
        
        if ($(this).find('.product-miniature:hidden').length == 0) {
            $(this).find('.load-more__product').addClass('disable');
        }
    });
    $('body').on('click touchstart', '.load-more__product', function (e) {
        e.preventDefault();    
        if($(this).parents('.six-row').length > 0) {
          $(this).closest('.homepage-products__wrapper').find('.product-miniature:hidden').slice(0, mediaProductCount2).slideDown();
        }
        else if ($(this).parents('.eight-row').length > 0) {
          $(this).closest('.homepage-products__wrapper').find('.product-miniature:hidden').slice(0, mediaProductCount).slideDown();
        }
        else {
          $(this).closest('.homepage-products__wrapper').find('.product-miniature:hidden').slice(0, mediaProductCount).slideDown();
        }
        if ($(this).closest('.homepage-products__wrapper').find('.product-miniature:hidden').length == 0) {
            $(this).addClass('disable');
        }
    });

    $('.homepage-section__custom-info-list').each(function() {
      if($(this).hasClass('custom-info-list-brands')) {
        $(this).find('li').slice(0, mediaInfoHtmlCountDefBrands).css({'display':'flex'});
      }
      else {
        $(this).find('li').slice(0, mediaInfoHtmlCountDef).css({'display':'flex'});
      }
      if ($(this).find('li:hidden').length == 0) {
        $(this).next('.btn').addClass('disable');
    }
    });
    $('body').on('click', '.load-more__custom-info', function (e) {
        e.preventDefault();
        $(this).closest('.homepage-section__custom-info').find('.homepage-section__custom-info-list li:hidden').slice(0, mediaInfoHtmlCountShow).slideDown();
        if ($(this).closest('.homepage-section__custom-info').find('.homepage-section__custom-info-list li:hidden').length == 0) {
            $(this).addClass('disable');
        }
    });


    $('.homepage-section__custom-info-heading-group').each(function() {

      if ($(this).find('p span').length > 0) {
          $(this).find('p span').on('click', function(){
            $(this).parent('p').css({'display':'block'});
            $(this).hide();
          });
      }
    });


    function hideSpecificStrokes(){
      var iso_code = prestashop.language.iso_code;
      var strokeObj = {
       it: 'Ricambi per',
       en: 'Spare parts for',
       fr: 'Pièces détachées pour',
       de: 'Ersatzteile für',
       es: 'Piezas de repuesto para'
      }

      $('.homepage-section__custom-info-list').each(function() {  
        if($(this).parents('.homepage-section__custom-info').hasClass('hide-specific-strokes')) {
          $(this).find(`li p:contains(${strokeObj[iso_code]})`).each(function(){
            let string = $(this).text();
                string = string.replace(`${strokeObj[iso_code]}`,'');
                $(this).text(string);
         }); 
        }        
      });
    }


     /* Dynamic position for product tabs (block name = 'product_left__info') */

     function productTabsPosition(){
        let tabs = $('.page-product .main-product-bottom');
        if (tabs) {
            $(window).width() <= 767 ? tabs.addClass('md-bottom').appendTo($('#mobile__product_info')) : tabs.removeClass('md-bottom').appendTo($('#desktop__product_info'))
        }
    }
    productTabsPosition();
    $(window).resize(productTabsPosition);


    function productSchedaTabPosition(){
        let tabs = $('.page-product .main-product-bottom');
        if (tabs.find('.product-features-block')) {
            let scheda = tabs.find('.product-features-block');
            $(window).width() >= 767 ? scheda.addClass('md-bottom').appendTo($('.main-product-details .product-left .product-normal-layout')) :  scheda.removeClass('md-bottom').prependTo($('#mobile__product_info'))
        }
    }

    productSchedaTabPosition();
    $(window).resize(productSchedaTabPosition);

      /* Sticky blocks. Calculate position */
    
      function stickyPos(){

        if($("[data-sticky]")) { 
            let top = $('.main-header').height();
            $('[data-sticky]').css("top", top + 40 + 'px');
        }
    }

    stickyPos();
    $(window).resize(stickyPos);



     /* Homepage banners. Just simple horizontal slider */

     function renderScrollArrows(){

        if($(window).width() < 1221) {
           
            $(document).find('.banners-x-scroll').each(function(){
                
                let block = $(this),
                    scrollContainer = $(this).find('.homepage--banners'),
                    scrollSize = $(this).find('img').width(),
                    arrowsHtml = {
                        right: "<div class='ctrl-next-btn ctrl-btn'><i class='fa fa-arrow-right'></i></div>",
                        left:  "<div class='ctrl-prev-btn ctrl-btn' style='display:none'><i class='fa fa-arrow-left'></i></div>"
                    };

                if ($(this).find('.ctrl-next-btn, .ctrl-prev-btn').length == 0) {          
                        block.append(arrowsHtml.left).append(arrowsHtml.right);          
                }

                // define scroll buttons

                let btnNext = block.find('.ctrl-next-btn'),
                    btnPrev = block.find('.ctrl-prev-btn');

                // int 8 - it's correction for current grid gap size in px       
                    btnNext.on('click', function(){

                    let currXPos =  scrollContainer.scrollLeft(); // get current scroll position
                    scrollContainer.scrollLeft(currXPos + scrollSize + 8);

                      setTimeout(function(){                      
                        if (scrollContainer.children().last().isOnScreen() == true) {
                            btnNext.hide();
                        } 
                            btnPrev.show();
                      },250);     
                });

                    btnPrev.on('click', function(){
                        let currXPos =  scrollContainer.scrollLeft();
                        scrollContainer.scrollLeft(currXPos - scrollSize - 8);
                        setTimeout(function(){                      
                            if (scrollContainer.children().first().isOnScreen() == true) {
                                btnPrev.hide();
                            } 
                                btnNext.show();
                        },250);  
                    });    
             }); 
            
        }

        else {          
            if ($(document).find('.ctrl-next-btn, .ctrl-prev-btn').length > 0) {          
                $(document).find('.ctrl-next-btn, .ctrl-prev-btn').remove();
            } 
        }
     } 


      // check if element is visible on viewport

      $.fn.isOnScreen = function(){

        let win = $(window),
    
            viewport = {
            top : win.scrollTop(),
            left : win.scrollLeft()
        };
        viewport.right = viewport.left + win.width();
        viewport.bottom = viewport.top + win.height();
    
        let bounds = this.offset();
        bounds.right = bounds.left + this.outerWidth();
        bounds.bottom = bounds.top + this.outerHeight();
    
        return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));
    
    };


     // renderScrollArrows();
     // $(window).resize(renderScrollArrows);

     
     function renderSidebarInMobileMenu(){
        if (window.matchMedia('(max-width: 768px)').matches) {
            if ($(document).find('#mobile-amegamenu .anav-top .js-toggle-linklist-mobile').length == 0 && $('.fixed-sidebar--sidebar .column-wrapper').length > 0) {
            let sidebar = $('.fixed-sidebar--sidebar .column-wrapper'),
                sidebarHtml = $('.fixed-sidebar--sidebar .op-links-block').html(),
                sidebarTitle = sidebar.find('.h3').text(),
                skeletonMenuItem = "<li class='amenu-item js-toggle-linklist-mobile'>" + 
                                   "<p class='h4 toggle-linklist-title'>" + sidebarTitle + "</p>" + 
                                   "<ul style='display:none;'><li>" + sidebarHtml + "</li></ul></li>"; 

                $(document).find('#mobile-amegamenu .anav-top .amenu-item:first-child').after(skeletonMenuItem);   
                                      
                let $content = $("#mobile-amegamenu .toggle-linklist-title").next();

                if ($content.length) {         
                    $("#mobile-amegamenu .toggle-linklist-title").on('click', function () {
                    $content.slideToggle();
                  });
                }
            }         
        }
     }

     renderSidebarInMobileMenu();
     $(window).resize(renderSidebarInMobileMenu);

     if ($('.cat--cms-links-list-inner')) {
        $('.cat--cms-links-list-inner').find('a').each(function(){
            let all = $(this).html(),
                text = '<br><span>' + all.split('<br>')[1] + '</span>';
                $(this).html(all.split('<br>')[0] + text);
        });
     }

     if($('[data-sticky-up]')) {

        $(function(){
    
          var didScroll = false;
          var lastScrollTop = 0;
          var delta = 5;
          var navbarHeight = $('[data-sticky-up]').outerHeight();
        
          $(window).scroll(function(event){
            didScroll = true;
          });
    
          setInterval(function() { 
            if (didScroll) {
              hasScrolled();
              didScroll = false;
            }
          });
    
          function hasScrolled() {
                var scrolltop = $(this).scrollTop();
                // Make sure they scroll more than delta
                if(Math.abs(lastScrollTop - scrolltop) <= delta)	return;
    
                if (scrolltop > lastScrollTop && scrolltop < navbarHeight){
                  // scroll down 
                  $('[data-sticky-up]').css({'top': ((scrolltop + 2) * -1) + 'px'});
                } 
                  else if (scrolltop > lastScrollTop && scrolltop > navbarHeight) {
                    $('[data-sticky-up]').removeClass('nav-down').addClass('nav-up').css({'top': ((navbarHeight + 2) * -1) + 'px'});
                   
                }
                else {
                  // scroll up   
                 if (scrolltop + $(window).height() < $(document).height()) {
                    $('[data-sticky-up]').removeClass('nav-up').addClass('nav-down').css({'transform':'translateY(0)', 'top':'0px'});
                  }
                  else {
                    $('[data-sticky-up]').removeClass('nav-up').removeClass('nav-down');
                  }
                }
    
                lastScrollTop = scrolltop;
          }
        });
    }


    if($('[data-animated-header-desktop]')) {

        var animatedSection = $('[data-animated-header-desktop]');
            
      
        function updatedHeaderBottomPosition(){
          var animatedSectionHeight = animatedSection.outerHeight();
             animatedSection.next('.header-bottom').css({"margin-top": animatedSectionHeight + 'px'});
        }
      
        //updatedHeaderBottomPosition();
        //$(window).resize(updatedHeaderBottomPosition);
      
      
        $(function(){
      
          var didScroll = false;
          var lastScrollTop = 0;
          var delta = 5;
          var navbarHeight = $('#header').outerHeight();
          var headerBottomHeight =  animatedSection.next('.header-bottom').outerHeight();
      
       
         
      
          $(window).scroll(function(event){
            didScroll = true;
          });
      
          setInterval(function() { 
            if (didScroll) {
              hasScrolled();
              didScroll = false;
            }
          },50);
      
          function hasScrolled() {
                var scrolltop = $(this).scrollTop();
                // Make sure they scroll more than delta
                if(Math.abs(lastScrollTop - scrolltop) <= delta)	return;
      
                if (scrolltop > lastScrollTop && scrolltop > navbarHeight){
                  // scroll down 
                 animatedSection.addClass('animated');
                } 
                else {
                  // scroll up   
                 if (scrolltop + $(window).height() < $(document).height()) {
                  if (scrolltop < (navbarHeight + headerBottomHeight)) {
                    animatedSection.removeClass('animated');
                  }
                    
                  }
                }
      
                lastScrollTop = scrolltop;
          }
      });
             
    }


    function swiperArrowsPosCorrectionByImage() {
        if ($('.swiper-initialized') && $('.swiper-initialized').find('.banner--text-section')) {
          $('.swiper-initialized').each(function(){
            let textHeight = $(this).find('.banner--text-section').outerHeight() / 2,
                wrapperHeight = $(this).find('.swiper-wrapper').outerHeight() / 2,
                topCorrection = wrapperHeight - textHeight;
           
            $(this).find('.swiper-button-prev, .swiper-button-next').each(function(){
                $(this).css({'top': topCorrection + 'px', 'visibility': 'visible'});
            });
      
          });
        }
      }
      
      swiperArrowsPosCorrectionByImage();
      $(window).resize(swiperArrowsPosCorrectionByImage);


      if ($('.main-product-details .product-quantity-touchspin').length > 0) {

        var inputQty = setInterval(function(){
            if ($('.main-product-details .product-quantity-touchspin').find('.input-group-btn')) {
              clearInterval(inputQty);
              $('.main-product-details .product-quantity-touchspin').find('.input-group-btn').each(function(){
                  qtyBehavior($(this));
              });   
            }
        },500);
      }
      
      function qtyBehavior(el){
          el.on('click',function(){
              el.find('button').focus();
              if ( el.parent('.input-group').find('#quantity_wanted').val() > 1) {
                el.parent('.input-group').find('.input-group-prepend').addClass('active')
              }
              else {
                el.parent('.input-group').find('.input-group-prepend').removeClass('active')
              }  
          });
      }


      /* Tooltip */
    
        function tooltip(){
            let tooltip = $('[data-tooltip]'),
                info = $('.tooltip-info');
        
            if (tooltip && ($("#mainProduct") || $('#quickviewProduct'))){
                tooltip.clone().removeClass('d-none').appendTo($(".indicator-with-tooltip"));
                //tooltip.clone().removeClass('d-none').appendTo($(".product-description-short").find("h3 > span:first-child"));
            }
        
            let tooltipAction = $(document).find("[data-tooltip]");
        
        tooltipAction.each(function(el){
            $(this).on('click touchstart', function(){
                info.addClass('show').appendTo($(this));
            });
        });
        
        $(document).on("click", function (event) {
            if ($(event.target).closest(".tooltip-info,[data-tooltip]").length === 0) {
                info.removeClass('show');
            }
            });
        }    
        
        tooltip();


        function lightboxImage() {
            const $gallery = $('#js_mfp_gallery');
            if ($gallery.length) {
              let zClose = $gallery.data('text-close'),
                  zPrev = $gallery.data('text-prev'),
                  zNext = $gallery.data('text-next');
          
              $('.js_mfp_gallery_item').magnificPopup({
                type: 'image',
                tClose: zClose,
                tLoading: '<div class="uil-spin-css"><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div></div>',
                removalDelay: 500,
                mainClass: 'mfp-fade',
                closeOnBgClick: true,
                gallery: {
                  enabled: true,
                  tPrev: zPrev,
                  tNext: zNext,
                  tCounter: '%curr% / %total%',
                },
                image: {
                  verticalFit: false,
                  titleSrc: function(item) {
                    if (item.el.attr('data-title')) {
                      return '<span>' + item.el.attr('data-title') + '</span>';
                    } else {
                      return '';
                    }
                  }
                }
              });
          
              $('.js-mfp-button-slider').on('click', function() {
                //let imageId = $('#js-zoom-gallery .js-thumb.selected').data('id-image');   
                  $('.js_mfp_gallery_item').filter('[data-id-image="' + $('.gallery-slider-container .swiper-slide-active').data('id-image') + '"]').trigger('click'); 
                
              });
            }
          }


          //Quickview scripts

$(document).find('.js-quick-view').each(function(){
    $(this).on('click',function(){
     let quickview = setInterval(function(){
       if ($('#quickviewProduct').length > 0) { 
         setTimeout(function(){
           // init tooltip
           tooltip();
           // init zoom magnificPopup
           lightboxImage(); 
           // init quantity logic
           if ($('#quickviewProduct .product-quantity-touchspin').length > 0) {
             var inputQty = setInterval(function(){
                 if ($('#quickviewProduct .product-quantity-touchspin').find('.input-group-btn')) {
                   clearInterval(inputQty);
                   $('#quickviewProduct .product-quantity-touchspin').find('.input-group-btn').each(function(){
                       console.log('span classes - ', $(this).attr('class'));
                       qtyBehavior($(this));
                   });   
                 }
             },500);
           }
   
           // init Swiper
           var quickviewSwiper= new Swiper(".gallery-slider", {
             loop: false,
             spaceBetween: 10,
             navigation: {
               nextEl: ".swiper-button-next",
               prevEl: ".swiper-button-prev",
             },
             autoplay: {
               delay: 3500,
               disableOnInteraction: false,
             },
             runCallbacksOnInit: true,
     
             on: {
               init: function(sw){
                
                 var offer = document.querySelector('#numberSlides');
                 offer.innerHTML = (sw.activeIndex +  1) + '/' + sw.slides.length;
               },
               slideChange: function(sw){
                 
                   var offer = document.querySelector('#numberSlides');
     
                   offer.innerHTML = (sw.activeIndex +  1) + '/' + sw.slides.length;
               }
             },
     
           });
   
           clearInterval(quickview);  
   
         },250);  
               
       }
     },500);
    });
   });


   $('[data-left-nav-trigger]').on('click', function(){
    var waitMenuLoaded = setInterval(function(){
      if( $('[data-st-menu]').find('.js-sidebar-categories').length > 0) {
        let heightCorrection = $('[data-st-menu]').find('#sidebar-top').outerHeight();
        $('[data-st-menu]').find('.js-sidebar-categories').css('max-height', `calc(100dvh - ${heightCorrection}px)`);
        clearInterval(waitMenuLoaded);
      }
    },500);
   });


  //  var fetchedInfo;
  //  $.get("https://ceruticenter.com/it/4528-husqvarna-ricambi-originali-giardinaggio", function(res) {
  //     fetchedInfo = $(res).find("div.total-products span").text();
  //    console.log(fetchedInfo);
  //  });


  const iso_code = prestashop.language.iso_code;
  const strokeObjExpand = {
      it: 'Leggi tutto',
      en: 'Read more',
      fr: 'Lire tout',
      de: 'Alles lesen',
      es: 'Leer todo'
  }

  const strokeObjHide = {
      it: 'Leggi di meno',
      en: 'Read less',
      fr: 'Lire moins',
      de: 'Weniger lesen',
      es: 'Leer menos'
     }

   function textExpand(){
    if ($(document).find('[data-expand]').length > 0) {
        $(document).find('[data-expand]').each(function(){
          $(this).text(strokeObjExpand[iso_code]);
          $(this).on('click', function(){
            $(this).toggleClass('readmore-less');
            $(this).prev().toggleClass('description-expanded');
            if($(this).hasClass('readmore-less')) {
                $(this).text(strokeObjHide[iso_code]);
            }
            else {
                $(this).text(strokeObjExpand[iso_code]);
            }
          });

          $(this).prev().on('click', function(){
            if ($(this).hasClass('description-expanded')) {
                $(this).removeClass('description-expanded');
                $(this).next('[data-expand').removeClass('readmore-less');
                $(this).next('[data-expand').text(strokeObjExpand[iso_code]);      
            }         
          });
        });
    }
  }

if($(document).find('#cms').length == 0) {
  textExpand();
}

function addLinks(){
  if ($(document).find('[data-expand]').length > 0) { 
  $(document).find('[data-expand]').each(function(){
   let element =  $(this).parent('.cms--links-element');
      if (element) {
      let link = element.find('a').attr('href'),
          wrapLink = `<a href='${link}' class='link'></a>`;
          wrapLink2 = `<a href='${link}' class='img-link'></a>`;
          element.find('h4').wrap(wrapLink);   
          element.find('div > img').wrap(wrapLink);
          element.find('> img').wrap(wrapLink2); 
      }   
  });
  }
}

  if ( $('[data-type=cms').length > 0) {
    var section =  $('[data-type=cms');
    var readMore = "<span class='readmore' data-expand>" + strokeObjExpand[iso_code] + "</span>";
       section.find('.cms--element-description').each(function(){
           $(this).after(readMore);
       });

       setTimeout(function(){

        textExpand();
        addLinks();

       },200);
  }



  // custom links for MostPopular & Featured Category modules on Homepage

  if($(document).find('.homepage-section__products').length > 0) {
    $(document).find('.homepage-section__products').each(function(){
      $(this).find('.btn-load').attr('href', $(this).find('.homepage-section__products_description').attr('data-link'));
    });
  }

});  //ready















